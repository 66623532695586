var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    param: _vm.popupParam,
                    disabledAppr: true,
                    workResultDatas: _vm.workResultDatas,
                    workResultEquips: _vm.workResultEquips,
                    workResultInout: _vm.workResultInout,
                    disabledObj: _vm.disabledObj,
                  },
                  on: {
                    "update:param": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:workResultDatas": function ($event) {
                      _vm.workResultDatas = $event
                    },
                    "update:work-result-datas": function ($event) {
                      _vm.workResultDatas = $event
                    },
                    "update:workResultEquips": function ($event) {
                      _vm.workResultEquips = $event
                    },
                    "update:work-result-equips": function ($event) {
                      _vm.workResultEquips = $event
                    },
                    "update:workResultInout": function ($event) {
                      _vm.workResultInout = $event
                    },
                    "update:work-result-inout": function ($event) {
                      _vm.workResultInout = $event
                    },
                    "update:disabledObj": function ($event) {
                      _vm.disabledObj = $event
                    },
                    "update:disabled-obj": function ($event) {
                      _vm.disabledObj = $event
                    },
                    disabledSet: _vm.disabledSet,
                    saveWorkResult: _vm.saveWorkResult,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }